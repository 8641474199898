define("formaviva-web/_pods/components/player/player-slave/component", ["exports", "formaviva-web/utils/track-gradient", "ember-uuid", "formaviva-web/config/environment"], function (_exports, _trackGradient2, _emberUuid, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === "test";

  var _default = Ember.Component.extend({
    messageBus: Ember.inject.service(),
    persistentPlayerState: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    containerId: Ember.computed("renderInto", "track.id", function () {
      if (Ember.isPresent(this.get("renderInto"))) {
        return this.get("renderInto");
      } else {
        return "player_slave_".concat(this.get("track.id"), "_").concat((0, _emberUuid.v4)());
      }
    }),
    isCurrentTrack: Ember.computed("track.id", "persistentPlayerState.currentTrack.id", function () {
      return this.get("track.id") === this.get("persistentPlayerState.currentTrack.id");
    }),
    showLoadingIndicator: Ember.computed.and("isCurrentTrack", "persistentPlayerState.showLoadingIndicator"),
    didRender: function didRender() {
      if (this.isFastBoot || isTest) {
        return;
      }

      if (this.get("renderedTrack.id") != this.get("track.id")) {
        if (this.player) this.player.destroy();
        this.renderPlayer();
      }
    },
    time: 0,
    renderPlayer: function renderPlayer() {
      this.set("renderedTrack", this.track);

      var _trackGradient = (0, _trackGradient2.default)(),
          linGradWave = _trackGradient.linGradWave,
          linGradProgress = _trackGradient.linGradProgress;

      var containerId = "#".concat(this.get("containerId"));
      var playerHeight = this.get("playerHeight") || 58;
      var barWidth = Ember.isPresent(this.get("barWidth")) ? this.get("barWidth") : 2;
      var cursorWidth = Ember.isPresent(this.get("cursorWidth")) ? this.get("cursorWidth") : 1;
      var player = window.WaveSurfer.create({
        container: containerId,
        waveColor: linGradWave,
        progressColor: linGradProgress,
        backend: "MediaElement",
        hideScrollbar: true,
        forceDecode: false,
        pixelRatio: 2,
        barWidth: barWidth,
        cursorWidth: cursorWidth,
        normalize: true,
        height: playerHeight,
        cursorColor: "#B56736"
      });
      this.setProperties({
        player: player,
        minutes: 0,
        seconds: 0
      }); // Map very low values up to a minimum value - to show dots in silent parts

      var min = 0.01;
      var peaks = this.get("track.peaks") || [];
      var adjustedPeaks = peaks.map(function (p) {
        return p <= min ? min : p;
      });
      player.backend.setPeaks(adjustedPeaks, this.track.duration); // Draw peaks immediately, without waiting for the first byte range to load

      player.drawBuffer(); // Setup user player interaction event handlers

      this.setupUserInteractionEvents(); // Setup event handlers coming from the other player components

      this.setupGlobalPlaybackEvents();
    },
    setupUserInteractionEvents: function setupUserInteractionEvents() {
      this.setupClick();
    },
    setupGlobalPlaybackEvents: function setupGlobalPlaybackEvents() {
      var _this = this;

      // Subscribe to events from other players in the application.
      this.messageBus.subscribe("playback:global:play", this, function (track) {
        // Play this component's track if the track from the message matches.
        _this.set("isPlaying", _this.trackMatches(track));
      }); // Stop playing on any pause event

      this.messageBus.subscribe("playback:global:pause", this, function () {
        _this.set("isPlaying", false);
      }); // When track progress is received for this track, render it.

      this.messageBus.subscribe("playback:global:seek", this, function (track, _ref) {
        var source = _ref.source,
            time = _ref.time,
            progress = _ref.progress,
            isPlaying = _ref.isPlaying;

        if (source === "slave") {
          return;
        }

        if (!_this.trackMatches(track)) {
          return;
        }

        _this.set("isPlaying", isPlaying);

        _this.set("time", time); // Render progress manually using wavesurfer's drawer.


        _this.player.drawer.progress(progress);
      });
    },
    setupClick: function setupClick() {
      var _this2 = this;

      this.$("wave").first().click(function (e) {
        // Calculate horizontal click position relative to its width
        // and calculate percent as progress.
        var clientX = e.clientX;

        var offsetX = _this2.$(".player-play-section").offset().left;

        var posX = clientX - offsetX;
        var canvasWidth = parseInt(_this2.$("canvas").css("width"));
        var progress = posX / canvasWidth;

        _this2.seek(progress);

        e.stopPropagation();
        return false;
      });
    },
    seek: function seek(progress) {
      var time = progress * this.track.length;
      this.set("time", time);
      var params = {
        source: "slave",
        progress: progress,
        time: time
      }; // Trigger play on canvas click, in case it is not playing yet.

      if (!this.get("isPlaying")) {
        this.send("play");
      }

      this.messageBus.publish("playback:global:seek", this.track, params);
    },
    trackMatches: function trackMatches(track) {
      return track.id === this.get("track.id");
    },
    publishPlay: function publishPlay(track, release) {
      var _this3 = this;

      // We need to know which release so that master player click can bring us
      // back to release.
      if (release) {
        track.set("releasePlayedFrom", release);
      } // When triggering a different track, wait for the master player
      // to be ready so it can receive the publish message.


      this.get("messageBus").publish("playback:global:play", track);

      var _waitForMasterPlayerToBeRendered = function _waitForMasterPlayerToBeRendered() {
        Ember.run.later(function () {
          var masterPlayerTrack = _this3.get("persistentPlayerState.masterPlayerComponent.currentTrack");

          if (masterPlayerTrack.id === track.id) {
            _this3.get("messageBus").publish("playback:global:play", track);
          } else {
            _waitForMasterPlayerToBeRendered();
          }
        }, 100);
      };

      _waitForMasterPlayerToBeRendered();
    },
    actions: {
      togglePlay: function togglePlay() {
        if (this.isPlaying) {
          this.send("pause");
        } else {
          this.send("play");
        }
      },
      play: function play() {
        this.set("isPlaying", true);
        this.get("persistentPlayerState").setProperties({
          currentTrack: this.get("track")
        }); // Begin trick for (Safari) browsers to not block playing.
        // When we asynchronously publish play event to master player, browser thinks this is
        // programatical autoplay, which is blocked. So we trigger it synchronosly first directly
        // from the user gesture event (click), which grants permission for further async play calls.
        // More info in PersistentPlayerState#init.

        window.player.play();
        window.player.pause(); // End trick.

        this.publishPlay(this.track, this.release);
        this.messageBus.publish("playback:global:play:user-initiated");
      },
      pause: function pause() {
        this.set("isPlaying", false);
        this.messageBus.publish("playback:global:pause", this.get("track"));
      }
    }
  });

  _exports.default = _default;
});