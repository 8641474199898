define("formaviva-web/_pods/labels/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    queryParams: ["page", "sort"],
    page: 1,
    sort: null,
    availableSortOptions: null,
    labels: Ember.computed.alias("model"),
    // prettier-ignore
    selectedSortOption: Ember.computed("sort", {
      // eslint-disable-line ember/require-computed-property-dependencies
      get: function get() {
        if (this._selectedSortOption) return this._selectedSortOption;
        var sort = this.sort;
        var sortOption = {
          label: "Newest on Formaviva",
          field: "approved_at",
          direction: "-"
        };

        if (sort) {
          if (sort.includes("name")) {
            sortOption.label = "Name (Z-A)";
            sortOption.field = "name";
            sortOption.direction = "-";

            if (!sort.includes("-")) {
              sortOption.label = "Name (A-Z)";
              sortOption.direction = "";
            }
          } else {
            if (!sort.includes("-")) {
              sortOption.direction = "";
              sortOption.label = "Oldest on Formaviva";
            }
          }
        }

        return sortOption;
      },
      set: function set(key, value) {
        return this._selectedSortOption = value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var sortOptions = [{
        label: "Newest on Formaviva",
        field: "approved_at",
        direction: "-"
      }, {
        label: "Oldest on Formaviva",
        field: "approved_at",
        direction: ""
      }, {
        label: "Name (Z-A)",
        field: "name",
        direction: "-"
      }, {
        label: "Name (A-Z)",
        field: "name",
        direction: ""
      }];
      this.set("availableSortOptions", sortOptions);
    }
  });

  _exports.default = _default;
});