define("formaviva-web/_pods/profile/route", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotFoundError = _emberData.default.NotFoundError;

  var _default = Ember.Route.extend({
    hasCustomAsyncTitle: true,
    queryParams: {
      justDonated: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      filterByType: {
        refreshModel: true
      }
    },
    fastboot: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    analyticsTracker: Ember.inject.service("analytics/event-tracker"),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    metrics: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      if (this.isFastBoot) return;

      if (model.merches) {
        model.merches.then(function (merches) {
          // 'isOpen' flag can be used during later stages
          // for our users to toggle the receipt of donation
          var donation = merches.filterBy("isDonation", true).filterBy("isOpen", true).firstObject;
          if (donation) controller.set("donation", _this.store.peekRecord("merch", donation.id));
        });
      }
    },
    fetchProfile: function fetchProfile(slug) {
      return this.store.queryRecord("profile", {
        slug: slug,
        find_by_slug: true
      });
    },
    fetchMerches: function fetchMerches(profileSlug) {
      return this.store.query("merch", {
        public: true,
        filter: {
          profile_slug: profileSlug
        }
      });
    },
    model: function model(_ref, transition) {
      var _this2 = this;

      var profile_slug = _ref.profile_slug,
          page = _ref.page,
          filterByType = _ref.filterByType;
      // We wait for the profile to be fetched from the server
      // and return it along with the tracks that are still fetching.
      // This way we don't block the rendering by waiting for all promises
      // to resolve - we only need the profile, the rest can load async.
      // we are fetching profile first because we need to check static-page if it fails
      var promise = this.fetchProfile(profile_slug).then(function (profile) {
        var requests = {
          profile: profile,
          merches: _this2.fetchMerches(profile_slug, true),
          isPublic: true,
          page: page,
          filterByType: filterByType
        }; // block render in fastboot

        return _this2.isFastBoot ? Ember.RSVP.hash(requests) : requests;
      }).catch(function (error) {
        // if slug does not match profile slug go to static-page
        if (error instanceof NotFoundError) {
          // queryParams are dropped during programmatic transition.
          // if an attempt is made to redirect with the queryParams option, queryParams
          // need to defined in the controller in order to be retained.
          // Reference: https://guides.emberjs.com/release/routing/redirection/#toc_transitioning-after-the-model-is-known
          var queryParams = transition.to.queryParams;

          if (queryParams) {
            var resourceController = _this2.controllerFor("static-page");

            var controllerQueryParams = resourceController.queryParams;
            var queryParamsKeys = Object.keys(queryParams);
            var concatedQueryParams = controllerQueryParams.concat(queryParamsKeys);
            resourceController.set("queryParams", concatedQueryParams);
          }

          _this2.replaceWith("static-page", profile_slug, {
            queryParams: queryParams
          });
        } else {
          throw error;
        }
      });
      return promise;
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.from.name !== transition.to.name) {
          this.controller.set("page", 1);
          this.controller.set("filterByType", null);
        }
      },
      loading: function loading(transition, originRoute) {
        if (originRoute.routeName === "profile") {
          return false;
        } else return true;
      },
      onDonationSuccess: function onDonationSuccess() {
        this.transitionTo({
          queryParams: {
            justDonated: true
          }
        });
      },
      didTransition: function didTransition() {
        this.get("analyticsTracker").track({
          profile_id: this.currentModel.profile.id,
          event: "view"
        });

        if (this.currentModel.profile.isLabel) {
          this.userBehaviourTracker.track({
            event: "label_page"
          });
        } else {
          this.userBehaviourTracker.track({
            event: "artist_page"
          });
        }

        return true; // Bubble the didTransition event
      }
    }
  });

  _exports.default = _default;
});