define("formaviva-web/_pods/components/cover-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6AIsul4Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"locked-overlay-image-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"isPrelaunchLocked\"]],\"blurred-element\"],null],\" fv-cover-image\"]]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"url\"]]],null,{\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"shouldLinkToRecord\"]],[29,\"eq\",[[25,[\"record\",\"recordType\"]],\"release\"],null]],null]],null,{\"statements\":[[4,\"link-to\",[\"release.show\",[25,[\"record\",\"profileSlug\"]],[25,[\"record\",\"slug\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\"],[11,\"data-cover-img\",\"\"],[12,\"src\",[23,\"url\"]],[11,\"alt\",\"\"],[11,\"role\",\"none\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"img\"],[11,\"data-cover-img\",\"\"],[12,\"src\",[23,\"url\"]],[11,\"alt\",\"\"],[11,\"role\",\"none\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/cover-image/template.hbs"
    }
  });

  _exports.default = _default;
});