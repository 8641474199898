define("formaviva-web/_pods/components/player/player-master/component", ["exports", "formaviva-web/constants/common", "formaviva-web/utils/play-reporter", "formaviva-web/config/environment", "formaviva-web/utils/track-gradient"], function (_exports, _common, _playReporter, _environment, _trackGradient2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === "test";

  var _default = Ember.Component.extend({
    persistentPlayerState: Ember.inject.service(),
    playerQueue: Ember.inject.service(),
    messageBus: Ember.inject.service(),
    localPersistence: Ember.inject.service(),
    userBehaviourTracker: Ember.inject.service("behaviour-tracking/tracker"),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    muted: Ember.computed.equal("currentVolume", 0),
    currentVolumePercent: Ember.computed("currentVolume", function () {
      return this.currentVolume * 100;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.setProperties({
        volumeSliderShown: false,
        currentVolume: this.localPersistence.getVolume(1),
        time: 0
      });

      if (_environment.default.environment === "test") {
        return;
      } // Every 100ms check if loading indicator needs to be shown.


      var showLoadingIndicatorIfNeeded = function showLoadingIndicatorIfNeeded() {
        Ember.run.later(function () {
          if (_this.get("player")) {
            var showLoadingIndicator = _this.get("isPlaying") && _this.$("audio")[0].buffering;

            _this.set("persistentPlayerState.showLoadingIndicator", showLoadingIndicator);
          }

          if (_this.destroyed) return;
          showLoadingIndicatorIfNeeded();
        }, 100);
      };

      showLoadingIndicatorIfNeeded();

      var reportToPlaySessionReporter = function reportToPlaySessionReporter() {
        Ember.run.later(function () {
          if (_this.reporter && _this.player.isPlaying()) {
            var time = _this.player.getCurrentTime();

            _this.reporter.trackIsPlaying(time);
          }

          if (_this.destroyed) return;
          reportToPlaySessionReporter();
        }, 300);
      };

      reportToPlaySessionReporter(); //bind the function to invoke when space bar is pressed

      this.set("toggleOnSpacePress", this.get("onSpacePress").bind(this));
    },
    didRender: function didRender() {
      if (this.isFastBoot || isTest) return; // Render new player when the track changed.
      // TODO: this is called every second. Perhaps use a different component hook, like didReceiveAttrs.

      if (this.get("track.id") !== this.get("currentTrack.id")) {
        if (this.get("player")) {
          if (this.reporter) {
            this.reporter.destroy();
          }

          this.get("player").destroy();
        }

        this.renderPlayer();
        this.get("persistentPlayerState").setProperties({
          currentTrack: this.get("track"),
          masterPlayerComponent: this
        });
        this.set("currentTrack", this.get("track")); //add event listener to the document after the player renders

        document.addEventListener("keydown", this.get("toggleOnSpacePress"), false);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.isFastBoot) {
        return;
      }

      this.cleanup();
    },
    cleanup: function cleanup() {
      if (this.reporter) {
        this.reporter.destroy();
      }

      if (this.player) {
        this.player.destroy();
      }

      this.messageBus.unsubscribe("playback:global:play", this);
      this.messageBus.unsubscribe("playback:global:pause", this);
      this.messageBus.unsubscribe("playback:global:seek", this);
      document.removeEventListener("keydown", this.toggleOnSpacePress, false);
    },
    didDestroyElement: function didDestroyElement() {
      this.cleanup();

      this._super.apply(this, arguments);
    },
    onSpacePress: function onSpacePress(event) {
      // logic handle play & pause track using spacebar
      // allow spacebar default functionality in input and textarea
      var textNodes = ["INPUT", "TEXTAREA"];

      if (event.code === "Space" && !textNodes.includes(event.target.nodeName)) {
        event.preventDefault();
        this.send("togglePlay");
      }
    },
    renderPlayer: function renderPlayer() {
      var _trackGradient = (0, _trackGradient2.default)(),
          linGradWave = _trackGradient.linGradWave,
          linGradProgress = _trackGradient.linGradProgress;

      var playerHeight = this.get("playerHeight") || 30;
      var barWidth = Ember.isPresent(this.get("barWidth")) ? this.get("barWidth") : 2;
      var cursorWidth = Ember.isPresent(this.get("cursorWidth")) ? this.get("cursorWidth") : 1;
      var player = window.WaveSurfer.create({
        container: this.element.getElementsByClassName("wave-wrapper")[0],
        waveColor: linGradWave,
        progressColor: linGradProgress,
        backend: "MediaElement",
        hideScrollbar: true,
        forceDecode: false,
        pixelRatio: 2,
        barWidth: barWidth,
        cursorWidth: cursorWidth,
        normalize: true,
        height: playerHeight,
        cursorColor: "#B56736"
      });
      this.setProperties({
        player: player,
        time: 0
      });
      var isDummyTrack = this.track && this.track.id == "dummy";

      if (!isDummyTrack) {
        // Setup event handlers coming from the component's player
        this.setupPlayerEvents(); // Setup event handlers coming from the other player components

        this.setupGlobalPlaybackEvents(); // Map very low values up to a minimum value - to show dots in silent parts

        var min = 0.01;
        var peaks = this.get("track.peaks") || [];
        var adjustedPeaks = peaks.map(function (p) {
          return p <= min ? min : p;
        });
        var trackUrl = this.get("track.lowQualityUrl"); // Load

        player.load(trackUrl, adjustedPeaks, "none"); // Draw peaks immediately, without waiting for the first byte range to load

        player.drawBuffer(); // Set volume in the player based on the last saved volume in local storage

        player.setVolume(this.get("currentVolume")); // Setup tracking for track session analytics

        this.setupTracking();
      } // For slave players


      window.player = player;
    },
    setupGlobalPlaybackEvents: function setupGlobalPlaybackEvents() {
      var _this2 = this;

      // Subscribe to events from other players in the application.
      this.get("messageBus").subscribe("playback:global:play", this, function (track) {
        // Play this component's track if the track from the message matches.
        if (_this2.trackMatches(track)) {
          if (!_this2.get("player").isPlaying()) {
            _this2.send("play");
          } // When some other track starts playing by some other player, pause this one.

        } else {
          _this2.send("pause");
        }

        _this2.get("persistentPlayerState").setCurrentPlayingTrack(_this2.get("track"));
      });
      this.messageBus.subscribe("playback:global:pause", this, function (track, source) {
        if (source === "master") {
          return; // Seeking will send playback:global:pause too, we don't want master to respond to it
        }

        _this2.send("pause");
      });
      this.messageBus.subscribe("playback:global:seek", this, function (track, _ref) {
        var source = _ref.source,
            progress = _ref.progress;

        if (source === "master") {
          return;
        }

        if (_this2.trackMatches(track)) {
          _this2.get("player").seekTo(progress);
        }
      });
    },
    setupPlayerEvents: function setupPlayerEvents() {
      var _this3 = this;

      var player = this.player;
      player.on("play", function () {
        _this3.get("messageBus").publish("playback:global:play", _this3.get("track")); // All next clicks after starting a track can interact (seek)


        Ember.run.later(function () {
          if (!_this3.get("player").params.interact) {
            _this3.get("player").toggleInteraction();
          }
        }, 100); // Doesn't work without a slight delay :/
      });
      player.on("pause", function () {
        _this3.messageBus.publish("playback:global:pause", _this3.track, "master");
      });
      player.on("seek", function (progress) {
        var time = progress * _this3.track.length;

        _this3.set("time", time);

        var params = {
          source: "master",
          isPlaying: _this3.isPlaying,
          progress: progress,
          time: time
        }; // to sync the slave players

        _this3.messageBus.publish("playback:global:seek", _this3.track, params);
      }); // stop playing and reporting when track finishes

      player.on("finish", function () {
        _this3.messageBus.publish("playback:global:finish", _this3.track, _this3.get("track.releasePlayedFrom"));

        _this3.send("pause");
      }); // fires continuously as the audio plays

      player.on("audioprocess", function (time) {
        _this3.set("time", time);

        var track = _this3.track;
        var progress = time / _this3.track.length;
        var params = {
          source: "master",
          isPlaying: true,
          time: time,
          progress: progress
        }; // to sync the slave players

        _this3.messageBus.publish("playback:global:seek", track, params);
      });
    },
    setAudioTagAttributes: function setAudioTagAttributes(track) {
      var footerPlayArea = this.element.getElementsByClassName("player-play-section")[0];
      var audioPlayer = footerPlayArea.getElementsByTagName("audio")[0];
      audioPlayer.setAttribute("title", "".concat(track.displayName, " | Formaviva"));
      audioPlayer.setAttribute("x-webkit-airplay", "allow");
    },
    trackMatches: function trackMatches(track) {
      return this.track ? track.id === this.track.id : false;
    },
    setupTracking: function setupTracking() {
      var reporter = _playReporter.default.create(Ember.getOwner(this).ownerInjection(), {
        track: this.track
      });

      reporter.clearBuffer();
      reporter.setCurrentSecretToken();
      this.set("reporter", reporter);
    },
    actions: {
      volumeSliderChanged: function volumeSliderChanged(percent) {
        var volume = percent / 100;
        this.set("currentVolume", volume);
        this.get("localPersistence").saveVolume(volume);
        this.player.setVolume(volume);
      },
      closeVolumeControllerDelayed: function closeVolumeControllerDelayed() {
        this._closeTimer = Ember.run.debounce(this, this.send, "closeVolumeController", 300);
      },
      openVolumeController: function openVolumeController() {
        Ember.run.cancel(this._closeTimer);
        this.set("volumeSliderShown", true);
      },
      closeVolumeController: function closeVolumeController() {
        Ember.run.cancel(this._closeTimer);
        this.set("volumeSliderShown", false);
      },
      togglePlay: function togglePlay() {
        if (this.isPlaying) {
          this.send("pause", true);
        } else {
          this.send("play");
        }
      },
      play: function play() {
        this.set("isPlaying", true);
        this.player.play();
        this.userBehaviourTracker.track({
          event: "play_track",
          track: this.track.displayName
        });
        this.messageBus.publish("playback:global:play:user-initiated");
        this.setAudioTagAttributes(this.track);
      },
      playNext: function playNext() {
        var _this4 = this;

        var currentTrack = this.playerQueue.currentTrack;
        var currentRelease = this.playerQueue.currentRelease;
        var track = this.playerQueue.nextTrack;

        if (track) {
          track.set("releasePlayedFrom", track.get("release"));
          this.persistentPlayerState.set("currentTrack", track); // Make sure the master player re-rendered with the
          // new track (didRender needs to be executed before we can publish the new track)

          Ember.run.scheduleOnce("afterRender", this, function () {
            var tracks = currentRelease.get("tracks");
            if (tracks.indexOf(currentTrack) >= _common.FEED_VISIBLE_TRACKS_COUNT) _this4.messageBus.publish("toggle:load-more", currentRelease);

            _this4.messageBus.publish("playback:global:play", track);
          });
        }
      },
      playPrev: function playPrev() {
        var _this5 = this;

        var currentTrack = this.playerQueue.currentTrack;
        var currentRelease = this.playerQueue.currentRelease;
        var track = this.playerQueue.prevTrack;

        if (track) {
          track.set("releasePlayedFrom", track.get("release"));
          this.persistentPlayerState.set("currentTrack", track); // Make sure the master player re-rendered with the
          // new track (didRender needs to be executed before we can publish the new track)

          Ember.run.scheduleOnce("afterRender", this, function () {
            var tracks = currentRelease.get("tracks");
            if (tracks.indexOf(currentTrack) >= _common.FEED_VISIBLE_TRACKS_COUNT) _this5.messageBus.publish("toggle:load-more", currentRelease);

            _this5.messageBus.publish("playback:global:play", track);
          });
        }
      },
      pause: function pause() {
        var trackEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.set("isPlaying", false);
        this.player.pause();

        if (trackEvent) {
          this.userBehaviourTracker.track({
            event: "pause_track",
            track: this.track.displayName
          });
        }
      }
    }
  });

  _exports.default = _default;
});