define("formaviva-web/_pods/components/action-btns/component", ["exports", "formaviva-web/mixins/track-download"], function (_exports, _trackDownload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_trackDownload.default, {
    tagName: "",
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    trackActions: Ember.inject.service("track/track-actions"),
    downloadOptions: Object.freeze(["mp3", "wav", "flac", "aiff"]),
    actions: {
      likeTrack: function likeTrack(track) {
        this.trackActions.likeTrack(track);
      },
      addToCart: function addToCart(track, release) {
        var _this = this;

        if (track) {
          if (track.mix) this.shoppingCart.addMixToCart(track);else this.shoppingCart.addTrackToCart(track, release);
        } else {
          // need to reload release to get all details otherwise addReleaseToCart/addMerchToCart is not working
          release.reload().then(function (release) {
            switch (release.recordType) {
              case "merch":
                _this.shoppingCart.addMerchToCart(release);

                break;

              case "release":
                _this.shoppingCart.addReleaseToCart(release);

                break;
            }
          });
        }
      },
      toggleSharePopup: function toggleSharePopup() {
        this.toggleProperty("sharePopupVisible");
      }
    }
  });

  _exports.default = _default;
});