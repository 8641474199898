define("formaviva-web/_pods/components/app-wide-modals/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    appWideModals: Ember.inject.service(),
    router: Ember.inject.service(),
    showSignup: true,
    actions: {
      goToPasswordReset: function goToPasswordReset() {
        this.send("closeLoginModal");
        this.get("router").transitionTo("password-reset");
      },
      closeLoginModal: function closeLoginModal() {
        var resetPendingAction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (resetPendingAction) {
          this.appWideModals.set("pendingAction", null);
        }

        this.get("appWideModals").toggleLoginModal(false);
      },
      showSignup: function showSignup() {
        this.set("showSignup", true);
      },
      showLogin: function showLogin() {
        this.set("showSignup", false);
      }
    }
  });

  _exports.default = _default;
});