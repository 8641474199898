define("formaviva-web/mixins/unsaved-record-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        var record = this.currentModel;
        var isNew = record.isNew;
        var unsaved;

        if (isNew) {
          var changedAttributes = record.changedAttributes();
          var changedAttributesKeys = Object.keys(changedAttributes);
          unsaved = changedAttributesKeys.filter(function (k) {
            return k !== "uuid";
          }).some(function (k) {
            return changedAttributes[k];
          });
        } else {
          unsaved = record.get("hasDirtyAttributes");
        }

        if (unsaved) {
          if (confirm("You have unsaved changes. Are you sure you want to leave without saving?")) {
            if (isNew) {
              record.destroyRecord();
            } else {
              record.rollbackAttributes();
            }

            return true;
          } else {
            transition.abort();
          }
        } else {
          if (isNew) {
            record.destroyRecord();
          }
        }
      }
    }
  });

  _exports.default = _default;
});